<template>
    <div class="ls-filter">
        <div class="filter "
             ref="box">
            <slot></slot>
            <el-input v-model="add.kw"
                      :placeholder="kw"
                      style="width: 380px;"></el-input>
            <el-button type="primary"
                       @click="_search">搜索</el-button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            table: {}
        },
        data() {
            return {
                add: {},
                kw: '',
                is_more: true,
                is_ex: false
            }
        },
        created() {
            this.$http.post('table/kw_field', {
                table: this.table
            }).then(res => {
                this.kw = `${res.data}关键词模糊搜索`;
            })
        },
        mounted() {
            let h = this.$refs.box.offsetHeight;
            console.log(h);
            let m = 63 + (99 - 63) / 2;
            if (h > m) {
                this.is_more = true;
            } else {
                this.is_more = false;
            }
        },
        methods: {
            _search() {
                this.$emit('change', this.add);
            }
        }
    }
</script>
<style lang="scss"
       scoped>
    @import "index.scss";
</style>