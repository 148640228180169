<template>
    <div :style="{color:info.c}" v-if="info&&info.t">
        {{info.t}}
    </div>
</template>
<script>
    export default {
        props: {
            info: {
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    } 
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>