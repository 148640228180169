<template>
    <div class="ls-box" v-if="vuex_is_localhost">
        <div class="flex hd">
            {{ table }}
            <el-button type="primary" @click="_table_info">刷新</el-button>
            <el-input v-model="r.name" placeholder="请输入字段名" ref="f_name"></el-input>
            <el-input v-model="r.comment" placeholder="请输入备注" @keyup.enter.native="_add_field">
            </el-input>
            <div>
                <el-input v-model="find_field" @keyup.enter.native="_find_field">
                </el-input>
                {{ tables_field }}
            </div>
        </div>
        <table class="table">
            <tr>
                <td>名称</td>
                <td>修改名称</td>
                <td>表格显示</td>
                <td>筛选</td>
                <td>备注</td>
                <td>类型</td>
                <td>宽度</td>
                <td>排序</td>
                <td>备注</td>
            </tr>
            <tr v-for="(item, index) in fields" :key="index" v-if="item.COLUMN_NAME != 'id'">
                <td align="left">
                    <div v-on:dblclick="_del_field(item.COLUMN_NAME)">
                        {{ item.COLUMN_NAME }}({{ item.COLUMN_TYPE }})
                    </div>
                </td>
                <td>
                    <input v-model="item.new_field" @blur="_edit(item.COLUMN_NAME, item)">
                </td>
                <td>
                    <el-switch v-model="item.is_show" :active-value="1" :inactive-value="0" @change="_quick(item.id, 'is_show', item.is_show)">
                    </el-switch>
                </td>
                <td>
                    <el-switch v-model="item.is_filter" :active-value="1" :inactive-value="0" @change="_quick(item.id, 'is_filter', item.is_filter)">
                    </el-switch>
                </td>
                <td>
                    <input v-model="item.COLUMN_COMMENT" @blur="_edit(item.COLUMN_NAME, item)" />
                </td>
                <td>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'text' })">text</span>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'int' })">int</span>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'tinyint' })">tinyint</span>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'decimal2' })">decimal2</span>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'decimal3' })">decimal3</span>
                    <span @click="_edit(item.COLUMN_NAME, { ...item, COLUMN_TYPE: 'date' })">date</span>
                </td>
                <td>
                    <input v-model="item.width" @blur="_quick(item.id, 'width', item.width)" />
                </td>
                <td>
                    <input v-model="item.myorder" @blur="_quick(item.id, 'myorder', item.myorder)" />
                </td>
                <td>
                    <input v-model="item.remark" @blur="_quick(item.id, 'remark', item.remark)" />
                </td>
            </tr>
        </table>
        <el-input type="textarea" autosize v-model="table_remark" @blur="_table_remark"></el-input>
        <el-input type="textarea" :value="table_info.form_str"></el-input>
        <el-input type="textarea" :value="table_info.table_str"></el-input>
    </div>
</template>
<script>
export default {
    props: {
        table: {}
    },
    data() {
        return {
            fields: [],
            table_info: {},
            r: {},
            table_remark: '',
            find_field: '',
            tables_field: ''
        }
    },
    created() {
        this._table_info();
    },
    methods: {
        _find_field() {
            this.$http.post('table/find_field', {
                field: this.find_field
            }).then(res => {
                this.tables_field = res.data;
            })
        },
        _table_remark() {
            this.$http.post('table/remark', {
                table: this.table,
                remark: this.table_remark
            })
        },
        _quick(id, field, value) {
            this._quick_edit('fields', id, field, value).then(res => {
                this._table_info();
            });
        },
        _edit(field, item) {
            this.$http.post('table/edit_field', {
                ...item,
                table: this.table,
                field
            }).then(res => {
                this._table_info();
            })
        },
        _add_field() {
            if (!this.r.name) {
                return false;
            }
            this.$http.post('table/add_field', {
                ...this.r,
                table: this.table,
            }).then(res => {
                this._table_info();
                this.$refs.f_name.focus();
            })
        },
        _del_field(field) {
            this.$confirm('确定删除').then(e => {
                this.$http.post('table/del_field', {
                    table: this.table,
                    field
                }).then(res => {
                    if (res.code) {
                        this._table_info();
                    }
                })
            })
        },
        _table_info() {
            this.$http
                .post("table/table_info", {
                    table: this.table,
                })
                .then((res) => {
                    this.table_info = res.data;
                    this.fields = res.data.fields;
                    this.table_fields = res.data.table_fields;
                    this.table_remark = res.data.table_remark;
                    this.$emit('fields', this.table_fields);
                });
        },
    }
}
</script>
<style lang="scss" scoped>
@import "index.scss";
</style>