<template>
    <div class="ls-upload">
        <template v-if="file">
            <el-upload :action="url" auto-upload :show-file-list="false" :on-success="_ok">
                <el-input :value="value" placeholder="点击上传">
                    <template slot="append">上传</template>
                </el-input>
            </el-upload>
        </template>
        <template v-else>
            <el-upload :action="url" auto-upload :show-file-list="false" :on-success="_ok" list-type="picture-card"
                :disabled="disabled" :drag="drag">
                <div v-if="value">
                    <img class="el-upload-list__item-thumbnail" :src="value" alt="">
                </div>
                <i slot="default" class="el-icon-plus" v-else></i>
            </el-upload>
        </template>
    </div>
</template>
<script>
    export default {
        props: {
            value: {},
            disabled: {
                default: false
            },
            drag: {
                default: false
            },
            file: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            url() {
                return `${this.$domain}/admin.php/file/upload`;
            },
        },
        data() {
            return {
            }
        },
        methods: {
            _ok(res, file) {
                this.$emit('input', res.data.url);
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>