import Vue from 'vue'
import self from '@/main.js';
import Router from 'vue-router'
// 引入依赖
import autoRouter from 'vue-router-auto'
Vue.use(Router)
let routes = autoRouter({
    // 页面级的.vue存放位置，必传
    rc: require.context('@/pages', true, /\.vue$/),
    // '/'的重定向，可选，默认为''
    redirect: '/index',
    // 页面级的.vue存放的文件夹，可选，默认为:views
    rootFile: 'pages',
})




export default new Router({
    routes
})
