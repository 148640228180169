<template>
    <div class="ls-mask" @click="_click" :style="{ zIndex: zIndex }">
        <div @click.stop :style="{ width: width }">
            <div class="tit" v-if="title">
                {{ title }}
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        width: {
            default: '50vw'
        },
        title: {
            default: ''
        },
        zIndex: {
            default: 9999
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        _click() {
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "index.scss";
</style>