<template>
    <div class="flex ls-box">
        <el-avatar icon="el-icon-user-solid" :src="item.pic"></el-avatar>
        <div class="ml-10">
            <div>
                {{item.user_name}}<br>
                {{item.phone}}
            </div>
            <div class="f-129">
                {{item.address}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {

        props: {
            value: {},
            item: {
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>