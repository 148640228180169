<template>
    <div>
        <el-date-picker type="date" v-model="value_temp" value-format="yyyy-MM-dd">
        </el-date-picker>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0
        }
    },
    watch: {
        value_temp(val) {
            let int = this._date_int(val);
            this.$emit('input', int);
        },
        value(val) {
            this.value_temp = this._int_date(val);
        }
    },
    data() {
        return {
            value_temp: 0,
        }
    },
    mounted() {
        this.value_temp = this._int_date(this.value);
    },
    methods: {
        _int_date(int) {
            if (!int) {
                return '';
            }
            // 比如需要这样的格式 yyyy-MM-dd hh:mm:ss
            var date = new Date(int * 1000);
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';

            return Y + M + D;
        },
        _date_int(date) {
            if (!date) {
                return 0;
            }
            // 也很简单
            // var date = new Date(strtime);
            //传入一个时间格式，如果不传入就是获取现在的时间了，这样做不兼容火狐。
            // 可以这样做
            var date = new Date(date.replace(/-/g, '/'));
            // 有三种方式获取，在后面会讲到三种方式的区别
            // time1 = date.getTime();
            // time2 = date.valueOf();
            let time = (Date.parse(date)) / 1000;
            return time;
            /* 
            三种获取的区别：
            第一、第二种：会精确到毫秒
            第三种：只能精确到秒，毫秒将用0来代替
            比如上面代码输出的结果(一眼就能看出区别)：
            1398250549123
            1398250549123
            1398250549000 
            */
        }
    }
}
</script>
<style lang="scss" scoped>
@import "index.scss";
</style>