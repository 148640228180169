import axios from 'axios';
import self from '@/main.js';
import {
    Message
} from 'element-ui';
let $http = axios.create({
    timeout: 50000,
    // withCredentials: true, // 允许携带cookie
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
});
$http.interceptors.request.use(function (config) {
    if (!config.baseURL) {
        config.baseURL = self.$domain + '/api.php/';
    }


    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});
// 添加响应拦截器
$http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let res = response.data;
    if (res.data == -1) {
        self.$router.push({
            path: '/login'
        });
    }
    if (res.msg && res.code && res.msg != '获取成功') {
        Message({
            message: res.msg,
            type: 'success'
        })
    }
    if (res.code == 0 && res.msg) {
        Message({
            message: res.msg,
            type: 'error'
        })
    }
    return Promise.resolve(response.data);
}, function (error) {
    return Promise.reject(error);
});
export default $http
