<template>
    <div id="app">
        <router-view :key="key"></router-view>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    data() {
        return {

        };
    },
    computed: {
        key() {
            return this.$route.fullPath + new Date();
        },

    },

    methods: {

    },
};
</script>
<style lang="scss">
@import "css/common.scss";
@import "css/style.scss";
</style>