<template>
    <div>
        <div>
            <div class="flex">
                <el-input v-model="address" placeholder="输入地点" v-on:keydown.native.enter="_lonlat"></el-input>
                <el-button type="primary" @click="_lonlat">获取经纬度</el-button>
                <div class="f-129 ml-10">
                    {{lon}},{{lat}}
                </div>
                <el-button type="primary" @click="_ok">确定</el-button>
            </div>
        </div>
        <div id="map_container"></div>
    </div>
</template>
<script>
    export default {
        props: {
            val: {},
            item: {
                default() { return {} }
            }
        },
        data() {
            return {
                address: this.item.address,
                lon: this.item.lon,
                lat: this.item.lat,
                map: null,
                geocoder: null,
                marker: null,
            }
        },
        computed: {
            lon_lat() {
                return this.lon + ',' + this.lat;
            }
        },
        watch: {
            lon_lat(val) {
                let _this = this;
                let lnglat = this.lon_lat.split(',');
                this.geocoder.getAddress(lnglat, function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        _this.address = result.regeocode.formattedAddress;
                    }
                });
            }
        },
        created() {
            setTimeout(() => {
                this._map();
            }, 1000);
        },
        methods: {
            _ok() {
                this.$emit('ok', { address: this.address, lon: this.lon, lat: this.lat })
            },
            _lonlat(e) {
                let _this = this;
                if (!this.address) {
                    return;
                };
                _this.geocoder.getLocation(this.address, function (status, result) {
                    if (status === 'complete' && result.geocodes.length) {
                        var lnglat = result.geocodes[0].location;
                        _this.marker.setPosition(lnglat);
                        _this.map.add(_this.marker);
                        _this.map.setFitView(_this.marker);
                    } else {
                        console.log('失败');
                    }
                });
                console.log(e);
            },
            _map() {
                var _this = this;
                var map = _this.map = new AMap.Map("map_container", {
                    resizeEnable: true
                });
                var marker = _this.marker = new AMap.Marker();
                map.on('click', function (e) {
                    console.log(e);
                    _this.address = '';
                    _this.lon = e.lnglat.getLng();
                    _this.lat = e.lnglat.getLat();
                    marker.setPosition(e.lnglat);
                    map.add(marker);
                    map.setFitView(marker);
                    // document.getElementById("lnglat").value = e.lnglat.getLng() + ',' + e.lnglat.getLat()
                });
                var geocoder = _this.geocoder = new AMap.Geocoder({
                    // city: "武汉", //城市设为北京，默认：“全国”
                });
                function geoCode() {
                    // var address = document.getElementById('address').value;
                    geocoder.getLocation(_this.address, function (status, result) {
                        if (status === 'complete' && result.geocodes.length) {
                            var lnglat = result.geocodes[0].location;
                            marker.setPosition(lnglat);
                            map.add(marker);
                            map.setFitView(marker);
                        } else {
                            console.log('失败');
                            // log.error('根据地址查询位置失败');
                        }
                    });
                };
                if (_this.address) {
                    geoCode();
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>