<template>
    <div>
        <div class="ls-pics">
            <img :src="item.url" alt="" v-for="(item,index) in arr" :key="index" @click="_click(item.url)">
        </div>
        <el-dialog :visible.sync="show_img" width="1000px" append-to-body>
            <img :src="img" alt="">
        </el-dialog>
    </div>
</template>
<script>
    export default {
        props: {
            files: {
                default() {
                    return [];
                }
            },
            field: {
                default: 'pic'
            }
        },
        computed: {
            arr() {

                return this.files.filter(item => {

                    return item.field == this.field;
                })
            }
        },
        data() {
            return {
                img: '',
                show_img: false
            }
        },
        created() {
        },
        methods: {
            _click(img) {
                this.img = img;
                this.show_img = true;
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "index.scss";
</style>