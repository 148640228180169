// import $ from 'jquery'
export default {
    data() {
        return {
            cfg: {},
            logs: [],
            cypts: [],
            jsexs: [],
            kj_index: 0,
            kjs: [],
            kj_info: {
                ss: 0,
                nums: []
            },
			datas: {
			    page: 0,
			    data: []
			},
            timer: 0,
            banners: [],
            next_info: {}
        }
    },
    created() {
        this._config(['app_name', 'logo',
            'ym',
            'ym1',
            'ym2',
            'ym3',
            'gg'
        ]);
        this._logs();
        this._cypts();
        this._jsexs();
        let width = window.innerWidth;
        width = Math.max(375, width);
        width = Math.min(420, width);
        document.documentElement.style.fontSize = (width / 375) + 'px';
        this._get_kjs(0);
        this._banners();
        this._load();
		
		//     $(".one-nav2 li a").click(function (){
		// 		console.log(222);
		// 		console.log(222);
		//         var objnav = this;
		//         if($(objbav).hasClass('one-nav2-active')){
		//             $(".one-nav2 li a").removeClass('one-nav2-active');
		//             $(".two-nav2-item").hide();
		//         }else{
		//             $(".one-nav2 li a").removeClass('one-nav2-active');
		//             $(objbav).addClass('one-nav2-active');
		//             var id = $(objbav).attr('data-id');
		//             $(".two-nav2-item").hide();
		//             $("#"+id).show();
		//         }
		
		//     });
		//     $(".two-nav2-item a").click(function (){
		//         $(this).parent('.two-nav2-item').hide();
		//     });
    },
    mounted() {
        // 监听滚动事件
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
			var obj = document.getElementById("topfixs");
			var ot = obj.offsetTop;
			var imgb = document.getElementById("navimg").offsetHeight;
			// console.log(imgb);
				var st = document.body.scrollTop || document.documentElement.scrollTop;
				// console.log(st);
				// obj.setAttribute("data-fixed",(st-ot)>=-60?"fixed":"");
				if((st-ot) >= -60){
					obj.setAttribute("data-fixed","fixed");
					obj.style.position = "fixed";
				}
				if(st <= imgb){
					obj.setAttribute("data-fixed","");
					obj.style.position = "static";
				}
        },
		_load() {
		    this.datas.loading = true;
		    this.$http.post('log/list', {
		        page: this.datas.page + 1,
		        order: {
		            qi: 'desc'
		        }
		    }).then(res => {
		        this.datas.loading = false;
		        if (res.code) {
		            this.datas.data = this.datas.data.concat(res.data.data[0]);
		            this.datas.page = res.data.current_page;
			// console.log(res.data.data[0]);
		        }
		    })
		},
        _rand_str(array) {
            let currentIndex = array.length, temporaryValue, randomIndex;
            // 当还剩有元素未洗牌时
            while (0 !== currentIndex) {
                // 选取剩下的元素
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                // 并与当前元素交换
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array.slice(0, 4).join(' ');
        },
        _banners() {
            this.$http.post('banner/list', {
                myorder: 'asc',
                limit: 100
            }).then(res => {
                if (res.code) {
                    this.banners = res.data.data;
                }
            })
        },
        _banner(index) {
            return this.banners[index]?.url;
        },
        _is_after2232(index) { // 函数名应更改为isAfter10PMAnd32Mins或类似名称以更清晰地表示其用途，但这里为了保持与原始问题一致而保留
            if (index > 0) {
                return true;
            } else {
                return false;
            }
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            // 检查小时是否大于22，或者小时等于22且分钟大于32
            return hours > 22 || (hours === 22 && minutes > 30);
        },
        _get_kjs(index) {
            this.kj_info = this.$options.data().kj_info;
            this.kj_index = index;
            if (index == 0) {
                this.kj_info.title = this.cfg.app_name || '新澳福彩';
                this.$http.get(`log/last`).then(res => {
                    if (res.code) {
                        this.next_info = res.data.next;
                        this.kj_info = res.data.last;
                        this.kj_info.qi = res.data.last.qi;
                        this.kj_info.nums = res.data.last.nums;
                     // this.$set(this.kj_info, 'ss', this._get_ss_hm('22点30分'));
                        this.$set(this.kj_info, 'ss', this._get_ss_hm('22点30分'));
                        clearInterval(this.timer);
                        this.timer = setInterval(() => {

                            this.kj_info.ss--;
                            if (this.kj_info.ss < 0 && this.kj_info.ss > -60 && this.kj_index == 0) {
                            // if (this.kj_info.ss < 0 && this.kj_info.ss > -1 && this.kj_index == 0) {
                                if (Number(this.kj_info.qi) != res.data.day_qi) {
                                    this._get_kjs(0);
                                }
                            }
                        }, 1000);
                    }
                });
                // this.$http.post('log/next_info').then(res => {
                //     if (res.code) {
                //         this.next_info = res.data;
                //     }
                // })
            }
        },
        _jsexs() {
            this.$http.post('jsex/list', {
                order: {
                    qi: 'desc'
                }
            }).then(res => {
                if (res.code) {
                    this.jsexs = res.data.data;
                }
            })
        },
        _cypts() {
            this.$http.post('cypt/list', {
            }).then(res => {
                if (res.code) {
                    this.cypts = res.data.data;
                }
            })
        },
        _logs() {
            this.$http.post('log/list', {
                is_all: 1,
                order: {
                    qi: 'desc'
                }
            }).then(res => {
                if (res.code) {
                    this.logs = res.data.data.map(item => {
                        return {
                            ...item,
                            nan: this._rand_str(['鼠', '牛', '虎', '龙', '马', '猴', '狗']),
                            nv: this._rand_str(['兔', '蛇', '羊', '鸡', '猪'])
                        };
                    })
                }
            })
        },
        formatTime(seconds) {
            if (seconds < 0) {
                return '00:00:00';
            }
            var hours = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds % 3600) / 60);
            var remainingSeconds = seconds % 60;
            // 格式化为时分秒的字符串
            var formattedTime = this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(remainingSeconds);
            return formattedTime;
        },
        pad(number) {
            return (number < 10) ? "0" + number : number;
        },
        extractHourAndMinute(timeString) {
            // 使用正则表达式匹配小时和分钟
            var match = timeString.match(/(\d{1,2})点(\d{1,2})分/);
            // 如果匹配成功，返回小时和分钟
            if (match) {
                var h = parseInt(match[1], 10);
                var m = parseInt(match[2], 10);
                console.log(h, m);
                return { h, m };
            }
            return { h: 0, m: 0 };
        },
        _get_ss_hm(time) {
            let { h, m } = this.extractHourAndMinute(time);
            // 获取当前时间
            var now = new Date();
            // 创建目标时间的 Date 对象
            var targetTime = new Date();
            targetTime.setHours(h, m, 0, 0);
            // 计算时间差（单位：毫秒）
            var timeDiff = targetTime - now;
            // 将毫秒转换为秒
            var ss = Math.floor(timeDiff / 1000);
            console.log('ss' + ss);
            // if(ss<0){
            // }
            if (ss <= -120) {
                return 24 * 3600 + ss;
            }
            // else if (ss <= 0) {
            //     return 0;
            // }
            return ss;
        },
		click1(){
			var obj = document.getElementById("two-nav21");
			var obj2 = document.getElementById("two-nav22");
			var obj3 = document.getElementById("two-nav23");
			var obj4 = document.getElementById("two-nav24");
			var obj5 = document.getElementById("two-nav25");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click2(){
			var obj = document.getElementById("two-nav22");
			var obj2 = document.getElementById("two-nav21");
			var obj3 = document.getElementById("two-nav23");
			var obj4 = document.getElementById("two-nav24");
			var obj5 = document.getElementById("two-nav25");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click3(){
			var obj = document.getElementById("two-nav23");
			var obj2 = document.getElementById("two-nav22");
			var obj3 = document.getElementById("two-nav21");
			var obj4 = document.getElementById("two-nav24");
			var obj5 = document.getElementById("two-nav25");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click4(){
			var obj = document.getElementById("two-nav24");
			var obj2 = document.getElementById("two-nav22");
			var obj3 = document.getElementById("two-nav23");
			var obj4 = document.getElementById("two-nav21");
			var obj5 = document.getElementById("two-nav25");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click5(){
			var obj = document.getElementById("two-nav25");
			var obj2 = document.getElementById("two-nav22");
			var obj3 = document.getElementById("two-nav23");
			var obj4 = document.getElementById("two-nav24");
			var obj5 = document.getElementById("two-nav21");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click11(){
			var obj = document.getElementById("two-nav1");
			var obj2 = document.getElementById("two-nav2");
			var obj3 = document.getElementById("two-nav3");
			var obj4 = document.getElementById("two-nav4");
			var obj5 = document.getElementById("two-nav5");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click21(){
			var obj = document.getElementById("two-nav2");
			var obj2 = document.getElementById("two-nav1");
			var obj3 = document.getElementById("two-nav3");
			var obj4 = document.getElementById("two-nav4");
			var obj5 = document.getElementById("two-nav5");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click31(){
			var obj = document.getElementById("two-nav3");
			var obj2 = document.getElementById("two-nav2");
			var obj3 = document.getElementById("two-nav1");
			var obj4 = document.getElementById("two-nav4");
			var obj5 = document.getElementById("two-nav5");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click41(){
			var obj = document.getElementById("two-nav4");
			var obj2 = document.getElementById("two-nav2");
			var obj3 = document.getElementById("two-nav3");
			var obj4 = document.getElementById("two-nav1");
			var obj5 = document.getElementById("two-nav5");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
		click51(){
			var obj = document.getElementById("two-nav5");
			var obj2 = document.getElementById("two-nav2");
			var obj3 = document.getElementById("two-nav3");
			var obj4 = document.getElementById("two-nav4");
			var obj5 = document.getElementById("two-nav1");
			if(obj.style.display == 'none'){
				obj.style.display = 'block';
			}else{
				obj.style.display = 'none';
			}
			obj2.style.display = 'none';
			obj3.style.display = 'none';
			obj4.style.display = 'none';
			obj5.style.display = 'none';
		},
    }
}